import { stopActionFetchPageData } from '../../utils/store';
import {
    fetchHomepageSectionsService,
    fetchHomepageFeedService, fetchBannersService,
} from '../../services';
import { ProductHistoryAdaptor } from '../../utils/adaptors';

export default {
    actions: {
        async fetchHomepageSections({
            commit, getters,
        }, {
            pageKey,
            dataProp,
        }) {
            const currentPage = getters.currentPage(pageKey);

            if (stopActionFetchPageData(currentPage, dataProp)) return;

            const response = {
                data: await fetchHomepageSectionsService({
                    $http: this.$http,
                }),
            };

            commit('pages/SET_PAGE_DATA', {
                prop: dataProp,
                data: response,
                routePath: pageKey,
                currentPage,
            }, { root: true });
        },

        async fetchHomepageFeed({
            commit, getters, rootState, rootGetters,
        }, {
            pageKey,
            dataProp,
            params,
        }) {
            const currentPage = getters.currentPage(pageKey);

            if (stopActionFetchPageData(currentPage, dataProp)) return;

            const accessToken = rootState.storage.api_v2_token;
            const storageProductHistory = (rootGetters['storage/parsedKeyValue']('product-history') || []);
            const productHistoryAdaptor = new ProductHistoryAdaptor(storageProductHistory);
            const productHistoryIds = productHistoryAdaptor.data.sorted.ids;

            try {
                const response = await fetchHomepageFeedService({
                    $http: this.$http,
                    params: {
                        ...(accessToken && { accessToken }),
                        ...(productHistoryIds && { productHistoryIds }),
                        ...params,
                    },
                });

                commit('pages/SET_PAGE_DATA', {
                    prop: dataProp,
                    data: response,
                    routePath: pageKey,
                    currentPage,
                }, { root: true });
            } catch (error) {
                console.error('fetchPageListingProductsService: ', error);
                throw error;
            }
        },

        async appendHomepageFeed({
            commit, rootState, rootGetters,
        }, {
            pageKey,
            dataProp,
            params,
        }) {
            try {
                const accessToken = rootState.storage.api_v2_token;
                const storageProductHistory = (rootGetters['storage/parsedKeyValue']('product-history') || []);
                const productHistoryAdaptor = new ProductHistoryAdaptor(storageProductHistory);
                const productHistoryIds = productHistoryAdaptor.data.sorted.ids;

                const response = await fetchHomepageFeedService({
                    $http: this.$http,
                    params: {
                        ...(accessToken && { accessToken }),
                        ...(productHistoryIds && { productHistoryIds }),
                        ...params,
                    },
                });

                commit('pages/SET_PAGE_DATA', {
                    prop: `${dataProp}.items`,
                    data: response.items,
                    routePath: pageKey,
                }, { root: true });

                commit('pages/SET_PAGE_DATA', {
                    prop: `${dataProp}.pagination`,
                    data: response.pagination,
                    routePath: pageKey,
                }, { root: true });
            } catch (error) {
                console.error('fetchPageListingProductsService: ', error);
                throw error;
            }
        },

        async fetchHomepageBanners({
            commit, getters, rootState,
        }, {
            pageKey,
            dataProp,
        }) {
            const currentPage = getters.currentPage(pageKey);

            if (stopActionFetchPageData(currentPage, dataProp)) return;

            try {
                const response = await fetchBannersService({
                    $http: this.$http,
                    params: {
                        source: rootState.params.source,
                        lang: rootState.params.country.lang,
                        position: 'top',
                    },
                });

                commit('pages/SET_PAGE_DATA', {
                    prop: dataProp,
                    data: response,
                    routePath: pageKey,
                }, { root: true });
            } catch (error) {
                console.error('fetchBannersService', error);
            }
        },

        setLastItemIndex({
            commit,
        }, {
            pageKey,
            dataProp,
            index,
        }) {
            commit('pages/SET_PAGE_DATA', {
                prop: `${dataProp}.lastItemIndex`,
                data: index,
                routePath: pageKey,
            }, { root: true });
        },
    },
};
