<template>
    <div v-if="pageData.loading === 'DONE'">
        <div class="container-fluid">
            <template v-if="dataBanners?.top?.length">
                <banner
                    v-if="dataBanners.top.length === 1"
                    :data="dataBanners.top[0]"
                    class="mb-4"
                    :lazy="false"
                />

                <base-slider
                    v-else
                    class-inner="row g-0"
                    class-slide="col-12"
                    :controls="false"
                    :indicators="{ style: 'bullets' }"
                    :interval="5000"
                >
                    <template
                        v-for="(item, index) in dataBanners.top"
                        #[index]
                    >
                        <banner
                            :key="index"
                            :data="item"
                            class="mb-4"
                            :lazy="index!==0"
                        />
                    </template>
                </base-slider>
            </template>

            <template v-if="dataSections">
                <section
                    v-for="(section, key) in dataSections"
                    :key="key"
                    class="mb-5"
                >
                    <template v-if="section.data">
                        <h2
                            v-if="section.name"
                            class="section-title h5 mb-3"
                        >
                            <span class="me-2">
                                {{ $t(`view_site_homepage_section_${section.name}`) }}
                            </span>
                            <router-link
                                v-if="section.type === 'collectionSlider'"
                                :to="{ name: 'ListingCollections' }"
                                class="small"
                            >
                                {{ $t('view_site_see_more') }}
                            </router-link>
                        </h2>
                        <base-list-product-h
                            v-if="section.type === 'productSlider'"
                            :products="section.data"
                            :query-params="section.queryParams"
                        />
                        <base-list-collection-h
                            v-else-if="section.type === 'collectionSlider'"
                            :items="section.data"
                            :first-images-eager="true"
                        />
                        <base-list-event-h
                            v-else-if="section.type === 'eventSlider'"
                            :items="section.data"
                        />
                        <base-list-profile-h
                            v-else-if="section.type === 'profileSlider'"
                            :items="section.data"
                        />
                    </template>
                </section>
            </template>

            <h2 class="section-title h5 mb-3">
                <span class="me-2">
                    {{ $t('view_site_homepage_section_banner_title') }}
                </span>
                <router-link
                    :to="{ name: 'AccountProfiles' }"
                    class="small"
                >
                    {{ $t('profile_listing_hero_button') }}
                </router-link>
            </h2>

            <RecycleScroller
                v-slot="{ item, index }"
                ref="wrapper"
                page-mode
                :items="dataFeed.items"
                :item-size="570"
                :item-secondary-size="gridSettings.itemWidth"
                :grid-items="gridSettings.columns"
                :prerender="10"
                :buffer="1090"
                key-field="id"
                @scroll-end="scrollEnd($event)"
            >
                <div class="feed-item">
                    <div
                        v-if="item.activity?.current?.persona"
                        class="item-header"
                    >
                        <base-avatar
                            :data="{
                                id: item.activity.current.persona.id,
                                nameInitials: item.activity.current.persona.initials
                            }"
                        />
                        <div class="activity">
                            <router-link
                                :to="{
                                    name: 'Profile',
                                    params: { id: item.activity.current.persona.id }
                                }"
                                target="_blank"
                            >
                                {{ item.activity.current.persona.alias }}
                            </router-link>
                            {{ $t(`view_site_homepage_feed_activity_${item.activity.type}`) }}
                        </div>
                    </div>
                    <base-item-product
                        v-if="item.type !== 'BREAK'"
                        :id="item.id"
                        :image-src="item.imageSrc"
                        :name="item.name"
                        :price="item.price"
                        :price-decimal="item.priceDecimal"
                        :complete-price="item.completePrice"
                        :price-conversion="item.priceConversion"
                        :price-sth="item.priceStrikethrough"
                        :flags="item.flags"
                        :currency="item.currency"
                        :slug="item.slug"
                        :badges="item.badges"
                        :query-params="(item.datalayer
                            && item.datalayer.queryParams)"
                        :image-loading="index < 3 && 'eager' || 'lazy'"
                        :is-in-feed="true"
                        @click.native="setLastItemIndex(index)"
                        @btn-favorites-action="$emit('btn-favorites-action', $event)"
                        @btn-action="$emit('btn-action', item)"
                        @btn-collection-product-removed="$emit('btn-collection-product-removed')"
                    />
                </div>
            </RecycleScroller>

            <div class="d-flex justify-content-center">
                <base-btn
                    variant="primary"
                    :loading="loadMoreButton.isLoading"
                    class="my-5 px-5"
                    @click.native="loadMore()"
                >
                    {{
                        !loadMoreButton.isLoading
                            ? $t('view_site_show_more')
                            : $t('view_site_loading')
                    }}
                </base-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

import { RecycleScroller } from 'vue-virtual-scroller';

import { BREAKPOINTS_DEVICES, BREAKPOINTS } from '../utils/constants';
import { getSrcCF } from '../utils';
import { page } from '../utils/mixins';
import pagesHomeModule from '../store/modules/pages-homepage';

import BaseItemProduct from '../components/BaseItemProduct.vue';
import BaseAvatar from '../components/BaseAvatar.vue';
import BaseSlider from '../components/BaseSlider.vue';

const Banner = defineAsyncComponent(() => import('../components/Banner.vue'));
const BaseListCollectionH = defineAsyncComponent(() => import('../components/BaseListCollectionH.vue'));
const BaseListProductH = defineAsyncComponent(() => import('../components/BaseListProductH.vue'));
const BaseListEventH = defineAsyncComponent(() => import('../components/BaseListEventH.vue'));
const BaseListProfileH = defineAsyncComponent(() => import('../components/BaseListProfileH.vue'));

const settings = {
    pageKey: '/',
    dataPropSections: 'sections',
    dataPropFeed: 'feed',
    dataPropBanners: 'banners',
};

export default {
    name: 'PageHomepage',

    components: {
        BaseSlider,
        Banner,
        BaseListCollectionH,
        BaseListEventH,
        BaseListProductH,
        BaseListProfileH,
        RecycleScroller,
        BaseItemProduct,
        BaseAvatar,
    },

    mixins: [
        page({
            appBtnBackToTop: true,
            usePath: settings.pageKey,
        }),
    ],

    data: () => ({
        head: {
            links: [],
        },
        loadMoreButton: {
            isLoading: false,
            auto: false,
        },
        feedSettings: {
            gridItems: 1,
            itemWidth: 402,
        },
        wrapperClientWidth: 0,
    }),

    head() {
        return {
            link: this.head.links,
        };
    },

    computed: {
        dataSections() {
            return this.pageData[settings.dataPropSections]?.data || false;
        },

        dataFeed() {
            return this.pageData[settings.dataPropFeed] || false;
        },

        dataBanners() {
            return this.pageData[settings.dataPropBanners] || false;
        },

        lastIndex() {
            return this.dataFeed?.lastItemIndex || null;
        },

        gridSettings() {
            const breakpoints = {
                xs: 1,
                sm: 1,
                md: 2,
                lg: 2,
                xl: 3,
            };

            const columns = breakpoints[this.$store.state.ui.client.breakpoint] || 3;
            const itemWidth = this.wrapperClientWidth / columns;

            return {
                itemWidth,
                columns,
            };
        },
    },

    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();

        if (this.lastIndex) {
            setTimeout(() => {
                this.scrollToIndex(this.lastIndex);
            }, 0);
        }
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },

    methods: {
        registerModule({ store: thisStore = this.$store } = {}) {
            if (thisStore.hasModule(['pages', 'pageHomepage'])) return;

            thisStore.registerModule(['pages', 'pageHomepage'], pagesHomeModule, { preserveState: true });
        },
        async pageFetch({
            store: thisStore = this.$store,
            route: thisRoute = this.$route,
        }) {
            await Promise.all([
                thisStore.dispatch('pages/fetchHomepageSections', {
                    pageKey: settings.pageKey,
                    dataProp: settings.dataPropSections,
                }),
                thisStore.dispatch('pages/fetchHomepageFeed', {
                    pageKey: settings.pageKey,
                    dataProp: settings.dataPropFeed,
                    params: {
                        'per-page': 30,
                        page: thisRoute.query.page || 1,
                    },
                }),
                thisStore.dispatch('pages/fetchPageMeta', {
                    route: thisRoute,
                    type: 'homepage',
                    usePath: settings.pageKey,
                }),
                thisStore.dispatch('pages/fetchHomepageBanners', {
                    pageKey: settings.pageKey,
                    dataProp: settings.dataPropBanners,
                }),
            ]);

            const preloadCollectionSliderItem = this.dataSections
                .filter((section) => section.type === 'collectionSlider')
                .map((section) => section.data[0])
                .find((item) => item !== undefined);

            if (preloadCollectionSliderItem) {
                this.head.links.push({
                    rel: 'preload',
                    href: getSrcCF(preloadCollectionSliderItem.coverImg, '472x236'),
                    as: 'image',
                    fetchpriority: 'high',
                });
            }

            this.head.links.push({
                rel: 'preload',
                href: this.dataFeed.items[0].imageSrc,
                as: 'image',
                fetchpriority: 'high',
            });

            if (this.dataBanners.top.length) {
                const firstHeroImage = this.dataBanners.top[0].manifest.phone.image;

                this.head.links.push(
                    ...[
                        {
                            rel: 'preload',
                            href: getSrcCF(firstHeroImage, '330x94'),
                            as: 'image',
                            fetchpriority: 'high',
                            media: `(max-width: ${BREAKPOINTS_DEVICES.motoG4}px)`,
                        },
                        {
                            rel: 'preload',
                            href: getSrcCF(firstHeroImage, '373x106'),
                            as: 'image',
                            fetchpriority: 'high',
                            media: `(max-width: ${BREAKPOINTS_DEVICES.motoGPower}px)`,
                        },
                        {
                            rel: 'preload',
                            href: getSrcCF(firstHeroImage, '784x223'),
                            as: 'image',
                            fetchpriority: 'high',
                            media: `(max-width: ${BREAKPOINTS.md}px)`,
                        },
                        {
                            rel: 'preload',
                            href: getSrcCF(firstHeroImage, '1200x342'),
                            as: 'image',
                            fetchpriority: 'high',
                            media: `(min-width: ${BREAKPOINTS.md + 1}px)`,
                        },
                    ],
                );
            }
        },

        async loadMore() {
            this.loadMoreButton.auto = true;
            this.loadMoreButton.isLoading = true;
            await this.$store.dispatch('pages/appendHomepageFeed', {
                pageKey: settings.pageKey,
                dataProp: settings.dataPropFeed,
                params: {
                    'per-page': 30,
                    page: this.dataFeed.pagination.current_page + 1,
                },
            });
            this.loadMoreButton.isLoading = false;
        },

        scrollEnd() {
            if (this.loadMoreButton.auto) {
                this.loadMore();
            }
        },

        handleResize() {
            if (this.resizeTimeoutID) {
                clearTimeout(this.resizeTimeoutID);
            }

            this.resizeTimeoutID = requestAnimationFrame(() => {
                this.wrapperClientWidth = this.$refs.wrapper.$el.clientWidth;
            });
        },

        setLastItemIndex(index) {
            this.$store.dispatch('pages/setLastItemIndex', {
                pageKey: settings.pageKey,
                dataProp: settings.dataPropFeed,
                index,
            });
        },

        scrollToIndex(index) {
            this.$refs.wrapper.scrollToItem(index);
        },
    },
};
</script>

<style lang="scss">
@use "sass:map";

@import "./../scss/required";

@import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

// this is a temporary class. please do not copy/propagate this
.row-collections {
    .img-responsive {
        overflow: hidden;
        border: $border-width solid $gray-300;
        border-radius: $border-radius;
    }
}

.section-title * {
    display: inline-block;
}

.vue-recycle-scroller {
    padding: calc(2 * $spacer) 0;
}

.feed-item {
    width: 336px;
    margin: 0 auto map.get($spacers, 4) auto;
    padding-bottom: map.get($spacers, 2);
    background-color: $white;
    border-radius: $border-radius;
    border: $border-width solid transparent;

    .item-product {
        border: none;
    }

    &:hover {
        border-color: $gray-200;
    }
}

.item-header {
    display: flex;
    gap: map.get($spacers, 2);
    align-items: center;
    padding: $spacer;

    .activity {
        max-width: 280px;
        max-height: 3.6em;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: $font-size-sm;

        a {
            font-weight: $font-weight-bold;
        }
    }
}
.vue-recycle-scroller__item-wrapper {
    display: flex;
    flex-wrap: wrap;
    row-gap: map.get($spacers, 4);
    justify-content: space-around;
}

.slider {
    margin-bottom: $spacer;

    .slider-indicators {
        bottom: 0;
    }
}
</style>
