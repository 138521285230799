var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.pageData.loading === "DONE"
    ? _c("div", [
        _c(
          "div",
          { staticClass: "container-fluid" },
          [
            _vm.dataBanners?.top?.length
              ? [
                  _vm.dataBanners.top.length === 1
                    ? _c("banner", {
                        staticClass: "mb-4",
                        attrs: { data: _vm.dataBanners.top[0], lazy: false },
                      })
                    : _c("base-slider", {
                        attrs: {
                          "class-inner": "row g-0",
                          "class-slide": "col-12",
                          controls: false,
                          indicators: { style: "bullets" },
                          interval: 5000,
                        },
                        scopedSlots: _vm._u(
                          [
                            _vm._l(_vm.dataBanners.top, function (item, index) {
                              return {
                                key: index,
                                fn: function () {
                                  return [
                                    _c("banner", {
                                      key: index,
                                      staticClass: "mb-4",
                                      attrs: { data: item, lazy: index !== 0 },
                                    }),
                                  ]
                                },
                                proxy: true,
                              }
                            }),
                          ],
                          null,
                          true
                        ),
                      }),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.dataSections
              ? _vm._l(_vm.dataSections, function (section, key) {
                  return _c(
                    "section",
                    { key: key, staticClass: "mb-5" },
                    [
                      section.data
                        ? [
                            section.name
                              ? _c(
                                  "h2",
                                  { staticClass: "section-title h5 mb-3" },
                                  [
                                    _c("span", { staticClass: "me-2" }, [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm.$t(
                                              `view_site_homepage_section_${section.name}`
                                            )
                                          ) +
                                          "\n                        "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    section.type === "collectionSlider"
                                      ? _c(
                                          "router-link",
                                          {
                                            staticClass: "small",
                                            attrs: {
                                              to: {
                                                name: "ListingCollections",
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm.$t("view_site_see_more")
                                                ) +
                                                "\n                        "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            section.type === "productSlider"
                              ? _c("base-list-product-h", {
                                  attrs: {
                                    products: section.data,
                                    "query-params": section.queryParams,
                                  },
                                })
                              : section.type === "collectionSlider"
                              ? _c("base-list-collection-h", {
                                  attrs: {
                                    items: section.data,
                                    "first-images-eager": true,
                                  },
                                })
                              : section.type === "eventSlider"
                              ? _c("base-list-event-h", {
                                  attrs: { items: section.data },
                                })
                              : section.type === "profileSlider"
                              ? _c("base-list-profile-h", {
                                  attrs: { items: section.data },
                                })
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "h2",
              { staticClass: "section-title h5 mb-3" },
              [
                _c("span", { staticClass: "me-2" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$t("view_site_homepage_section_banner_title")
                      ) +
                      "\n            "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "small",
                    attrs: { to: { name: "AccountProfiles" } },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("profile_listing_hero_button")) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("RecycleScroller", {
              ref: "wrapper",
              attrs: {
                "page-mode": "",
                items: _vm.dataFeed.items,
                "item-size": 570,
                "item-secondary-size": _vm.gridSettings.itemWidth,
                "grid-items": _vm.gridSettings.columns,
                prerender: 10,
                buffer: 1090,
                "key-field": "id",
              },
              on: {
                "scroll-end": function ($event) {
                  return _vm.scrollEnd($event)
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ item, index }) {
                      return [
                        _c(
                          "div",
                          { staticClass: "feed-item" },
                          [
                            item.activity?.current?.persona
                              ? _c(
                                  "div",
                                  { staticClass: "item-header" },
                                  [
                                    _c("base-avatar", {
                                      attrs: {
                                        data: {
                                          id: item.activity.current.persona.id,
                                          nameInitials:
                                            item.activity.current.persona
                                              .initials,
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "activity" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "Profile",
                                                params: {
                                                  id: item.activity.current
                                                    .persona.id,
                                                },
                                              },
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  item.activity.current.persona
                                                    .alias
                                                ) +
                                                "\n                        "
                                            ),
                                          ]
                                        ),
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t(
                                                `view_site_homepage_feed_activity_${item.activity.type}`
                                              )
                                            ) +
                                            "\n                    "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.type !== "BREAK"
                              ? _c("base-item-product", {
                                  attrs: {
                                    id: item.id,
                                    "image-src": item.imageSrc,
                                    name: item.name,
                                    price: item.price,
                                    "price-decimal": item.priceDecimal,
                                    "complete-price": item.completePrice,
                                    "price-conversion": item.priceConversion,
                                    "price-sth": item.priceStrikethrough,
                                    flags: item.flags,
                                    currency: item.currency,
                                    slug: item.slug,
                                    badges: item.badges,
                                    "query-params":
                                      item.datalayer &&
                                      item.datalayer.queryParams,
                                    "image-loading":
                                      (index < 3 && "eager") || "lazy",
                                    "is-in-feed": true,
                                  },
                                  on: {
                                    "btn-favorites-action": function ($event) {
                                      return _vm.$emit(
                                        "btn-favorites-action",
                                        $event
                                      )
                                    },
                                    "btn-action": function ($event) {
                                      return _vm.$emit("btn-action", item)
                                    },
                                    "btn-collection-product-removed": function (
                                      $event
                                    ) {
                                      return _vm.$emit(
                                        "btn-collection-product-removed"
                                      )
                                    },
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.setLastItemIndex(index)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                561344223
              ),
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex justify-content-center" },
              [
                _c(
                  "base-btn",
                  {
                    staticClass: "my-5 px-5",
                    attrs: {
                      variant: "primary",
                      loading: _vm.loadMoreButton.isLoading,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.loadMore()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          !_vm.loadMoreButton.isLoading
                            ? _vm.$t("view_site_show_more")
                            : _vm.$t("view_site_loading")
                        ) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }